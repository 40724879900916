import React, { Component } from 'react'
import { Form, Input, Button, Alert } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import isValidDomain from 'is-valid-domain'
import { formLayout, tailLayout, formItemLayout, formItemLayoutWithOutLabel } from '..'
import { FormProvider, FormConsumer } from '../../../context/FormContext'
import { DomainConsumer } from '../../../context/DomainContext'

type DomainFormProps = {
  distributionId: string,
  onSuccess: Function
}

export default class DomainForm extends Component<DomainFormProps> {

  static defaultProps = {
    onSuccess: () => {}
  }

  /**
   * Save values from form subimitting
   * 
   * @param values 
   */
  handleSave = (values: any, dispatch: Function) => {
    const { distributionId } = this.props
    dispatch({
      distribution: distributionId,
      domains: values.domains
    })
  }

  render() {
    return (
      <FormProvider>
        <FormConsumer>
          {({ form, onFormChange }) => {
            return (
              <DomainConsumer>
                {({ fetching, success, error, requestDomainAdd }) => {
                  // Our requested was made successfully
                  if (success) {
                    this.props.onSuccess()
                  }

                  return (
                    <Form
                      {...formLayout}
                      name="domains"
                      onFinish={(values) => this.handleSave(values, requestDomainAdd)}
                      onChange={onFormChange}
                    >
                      {
                        error ? (
                          <Alert type="error" message="Could not add domains" description={JSON.stringify(error)} style={{marginBottom: 20}} />
                        ) : null
                      }

                      <Form.List name="domains">
                        {(fields, { add, remove }) => {
                          // Create default field
                          if (fields.length === 0) {
                            setTimeout(() => {
                              add('')
                            }, 500)
                          }
      
                          return (
                            <div>
                              {
                                fields.map((field, index) => (
                                  <Form.Item
                                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                    label={index === 0 ? 'Domains' : ''}
                                    required={false}
                                    key={field.key}
                                  >
                                    <Form.Item
                                      {...field}
                                      noStyle
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          whitespace: true,
                                          message: 'Please input the domain here or remove this field'
                                        },
                                        () => ({
                                          validator(rule, value) {
                                            if (!isValidDomain(value)) {
                                              return Promise.reject('Invalid domain format')
                                            }
                                            /*if (value.length > 5) {
                                              requestDomainFind(value)
                                            }
                                            if (foundDomain) {
                                              return Promise.reject(`Domain already in use by ${foundDomain.distribution}`)
                                            }*/
      
                                            return Promise.resolve()
                                          }
                                        })
                                      ]}
                                    >
                                      <Input placeholder="domain.here" style={{ width: '60%' }} />
                                    </Form.Item>
                                    {
                                      fields.length > 1 ? (
                                        <MinusCircleOutlined
                                          className="dynamic-delete-button"
                                          style={{ margin: '0 8px' }}
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        />
                                      ) : null
                                    }
                                  </Form.Item>
                                ))
                              }
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add('')}
                                  style={{ width: '60%' }}
                                >
                                  <PlusOutlined /> Add more domains
                                </Button>
                              </Form.Item>
                            </div>
                          )
                        }}
                      </Form.List>

                      <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={fetching} style={{float: 'right'}}>
                          Save
                        </Button>
                      </Form.Item>
                    </Form>
                  )
                }}
              </DomainConsumer>
            )
          }}
        </FormConsumer>
      </FormProvider>
    )
  }
}
