import React, { createContext } from 'react'
import { BaseContextState, BaseContextProvider } from '../BaseContext'
import { loadStorage, saveStorage } from '../../services/storage'
import { RequestLogin } from '../../services/api.typings'
import { loginRequest } from '../../services/api'

// That's our local storage key
export const STORAGE_KEY = '@myp-domains-auth'

interface User {
  name: String,
  username: String
}

// Provider states
interface AuthContextState extends BaseContextState {
  loggedIn: boolean,
  accessToken: string
  refreshToken: string
  user: User | null,
}

// Context data
interface AuthContextData extends AuthContextState {
  loggedIn: boolean,
  accessToken: string
  refreshToken: string
  user: User | null,

  resetErrors: Function,
  requestLogin: Function,
  requestLogout: Function
}

// Create context
const { Provider, Consumer } = createContext({} as AuthContextData)

// Export consumer
export const AuthConsumer = Consumer

export class AuthProvider extends BaseContextProvider {

  state: AuthContextState = {
    loggedIn: false,
    accessToken: '',
    refreshToken: '',
    user: null
  }

  constructor(props: any) {
    super(props)

    const store = loadStorage(STORAGE_KEY)

    // We do have stored user data?
    if (store) {
      this.state = {
        ...this.state,
        ...store,
        loggedIn: true
      }
    }
  }

  /**
   * Make api request for login
   * 
   * @param payload 
   */
  requestLogin = (payload: RequestLogin) => {
    this.setState({
      fetching: true,
      error: null,
      errorObjects: null
    }, async () => {
      const response = await loginRequest(payload)

      // Process our response
      this.processResponse(response, ['accessToken', 'refreshToken'])
      
      if (this.state.accessToken.length > 0 && this.state.refreshToken.length > 0) {
        this.setState({
          loggedIn: true
        })

        saveStorage(STORAGE_KEY, {
          accessToken: this.state.accessToken,
          refreshToken: this.state.refreshToken,
          user: null // Not in use for now
        })
      }
    })
  }

  /**
   * Logout now!
   */
  requestLogout = () => {
    this.setState({
      fetching: false,
      error: null,
      errorObjects: null,
      loggedIn: false,
      accessToken: '',
      refreshToken: '',
      user: null,
    }, () => {
      localStorage.removeItem(STORAGE_KEY)
    })
  }

  render() {
    return (
      <Provider
        value={{
          // States
          ...this.state,

          // Dispatches
          resetErrors: this.resetErrors,
          requestLogin: this.requestLogin,
          requestLogout: this.requestLogout
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}
