import React, { createContext } from 'react'
import { BaseContextState, BaseContextProvider } from '../BaseContext'
import { taskListRequest } from '../../services/api'

// Distribution interface
export interface Task {
  id: number,
  distributionId: string,
  certificateArn: string,
  type: string,
  domains: string,
  stage: string,
  status: string,
  createdAt: string,
  updatedAt: string
}

// Provider states
interface TaskContextState extends BaseContextState {
  tasks: Array<Task> | null
}

// Context data
interface TaskContextData extends TaskContextState {
  resetErrors: Function,
  requestTaskList: Function
}

// Create context
const { Provider, Consumer } = createContext({} as TaskContextData)

// Export consumer
export const TaskConsumer = Consumer

export class TaskProvider extends BaseContextProvider {
  state: TaskContextState = {
    tasks: null
  }

  /**
   * Make api request for tasks listing
   */
  requestTaskList = () => {
    this.setState({
      fetching: true,
      error: null,
      errorObjects: null
    }, async () => {
      const response = await taskListRequest()

      // Process our response
      this.processResponse(response, ['tasks'])
    })
  }

  render() {
    return (
      <Provider
        value={{
          // States
          ...this.state,

          // Dispatches
          resetErrors: this.resetErrors,
          requestTaskList: this.requestTaskList
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}
