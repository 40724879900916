import React, { Component } from 'react'
import { setToken } from '../../services/api'
import { GenericResponse } from '../../services/api.typings'

export interface BaseContextData {
  fetching: boolean
  error: string | null
  errorObjects: any
}

export interface BaseContextState {
  fetching?: boolean,
  success?: boolean,
  error?: any,
  errorObjects?: any,
  deletedId?: any | null,
  pagination?: any | null
}

export class BaseContextProvider extends Component {

  state: BaseContextState = {
    fetching: false,
    success: false,
    error: null,
    errorObjects: null,
    deletedId: null,
    pagination: null
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { accessToken } = state

    if (typeof accessToken === 'string' && accessToken.length > 0) {
      setToken(accessToken)
    }

    return state
  }

  /**
   * Reset errors from context
   */
  resetErrors = () => {
    this.setState({
      error: null,
      errorObjects: null
    })
  }

  /**
   * Process every response of this provider
   * 
   * @param {GenericResponse} response 
   * @param {Array<string>} fields
   */
  processResponse = (response: GenericResponse, fields: Array<string> = []) => {
    const { ok, data, problem } = response

    if (ok) {
      const { total, limit, currentPage, deletedId }: any = data
      
      // We just need to update what the request returned :P
      let updateObj: any = {}

      // Parse our filtered fields
      fields.forEach(field => {
        if (data[field] !== undefined) {
          updateObj[field] = data[field]
        }
      })

      // Something was deleted?
      if (deletedId) updateObj['deletedId'] = deletedId

      // If we do currentPage key, so we are dealing with a pagination
      if (currentPage) {
        updateObj['pagination'] = {
          total,
          limit,
          currentPage
        }
      }

      // Update our provider component
      this.setState({
        fetching: false,
        success: true,
        error: null,
        errorObjects: null,

        ...updateObj
      })
    } else {
      // By default we should wait for a client error
      let error = problem
      let errorObjects = null

      // If we got some error from our API, so we should be aware of it
      if (data && data.error) {
        error = data.error

        // If we do have some objects error, we need them
        if (data.objects) {
          errorObjects = data.objects
        }
      }

      this.setState({
        fetching: false,
        success: false,
        error,
        errorObjects
      })
    }
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    )
  }
}
