import { create, HEADERS } from 'apisauce'
import Config from '../config'
import { STORAGE_KEY } from '../context/AuthContext'
import { loadStorage, saveStorage, clearStorage } from './storage'
import { GenericResponse, RequestLogin, RequestAddDomain, RequestRemoveDomain } from './api.typings'

// Create our ApiSauce instance
const api = create({
  baseURL: Config.baseAPI
})

// Simple call request
const callRequest = async (method: string, url: string, { params = {}, data = undefined, headers = {} }) => {
  if (method === 'get') {
    await api.get(url, params, { headers })
  }
  if (method === 'put') {
    await api.put(url, data, { headers })
  }
  if (method === 'post') {
    await api.post(url, data, { headers })
  }
  if (method === 'delete') {
    await api.delete(url, params, { headers })
  }
}

// Renew access token monitor
const renewTokenMonitor = async (response: any) => {
  const { ok, data, status, config }: any = response

  if (!ok && status === 403 && typeof data === 'object') {
    const { error } = data
    const { method, url, params, data: requestData, headers = {} } = config
    const session = loadStorage(STORAGE_KEY)

    // We did found any session, we should clear storage for security reasons
    if (!session) {
      clearStorage()
    }

    if (session) {
      const { refreshToken } = session

      if (typeof refreshToken === 'string' && refreshToken.length > 0) {
        // If we got blocked by session expired, we need to renew our access token
        if (error === 'session_expired') {
          const resp = await renewTokenRequest(refreshToken)

          // We have renewed our token :D
          if (resp.ok) {
            const renewData: any = resp.data
            saveStorage(STORAGE_KEY, {
              accessToken: renewData.accessToken,
              refreshToken: renewData.refreshToken
            })

            // Make call again
            headers['Authorization'] = `Bearer ${renewData.accessToken}`
            setTimeout(() => {
              callRequest(method, url, { params, requestData, headers } as any)
            }, 1000)
          } else {
            // Ooops, something went wrong, the better way to handle that, is logging out the user
            clearStorage()
          }
        }
      }
    }
    

    /*const { refreshToken } = loadStorage(STORAGE_KEY)

    // If we do have refresh token
    if (refreshToken && refreshToken.length) {
      // If we got blocked by session expired, we need to renew our access token
      if (error === 'session_expired') {
        const resp = await renewTokenRequest(refreshToken)

        // We have renewed our token :D
        if (resp.ok) {
          const renewData: any = resp.data
          saveStorage(STORAGE_KEY, {
            accessToken: renewData.accessToken,
            refreshToken: renewData.refreshToken,
            user: renewData.user
          })

          // Make call again
          headers['Authorization'] = `Bearer ${renewData.accessToken}`
          setTimeout(() => {
            callRequest(method, url, { params, requestData, headers } as any)
          }, 1000)
        } else {
          // Ooops, something went wrong, the better way to handle that, is logging out the user
          clearStorage()
        }
      }
    }*/
  }
}
api.addMonitor(renewTokenMonitor)

// Configuration
export const setHeaders = (opts: HEADERS) => api.setHeaders(opts)
export const setURL = (url: string) => api.setBaseURL(url)
export const setToken = (token: string) => api.setHeader('Authorization', `Bearer ${token}`)

// Methods
export const loginRequest = (data: RequestLogin) => api.post('v1/user/login', data) as Promise<GenericResponse>
export const renewTokenRequest = (refreshToken: string) => api.get(`v1/user/renew/${refreshToken}`) as Promise<GenericResponse>

export const distributionListRequest = () => api.get('v1/domain/distributions') as Promise<GenericResponse>
export const distributionDetailRequest = (id: string) => api.get(`v1/domain/distributions/${id}`) as Promise<GenericResponse>
export const domainListRequest = (distributionId?: string, domain?: string) => api.get(`v1/domain/${distributionId || ''}/${domain || ''}`) as Promise<GenericResponse>
export const domainAddRequest = (data: RequestAddDomain) => api.post('v1/domain/add', data) as Promise<GenericResponse>
export const domainRemoveRequest = (data: RequestRemoveDomain) => api.post('v1/domain/remove', data) as Promise<GenericResponse>
export const taskListRequest = () => api.get('v1/domain/tasks') as Promise<GenericResponse>
