import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Form, Input, Button, Typography, Card, Divider, Alert } from 'antd'
import { formLayout, tailLayout } from '..'
import { getErrorCode } from '../../../helpers/error-codes.helper'
import { FormConsumer } from '../../../context/FormContext'
import '../style.less'

type AuthFormProps = {
  loading: boolean,
  error: string | null,
  errorObjects: any,
  onLogin: Function,
  resetErrorsDispatch: Function,

  // Browser history
  history: any,
}

export default class AuthForm extends Component<AuthFormProps> {
  static defaultProps = {
    loading: false,
    error: null,
    onLogin: () => {},
    resetErrorsDispatch: () => {},
  }

  constructor(props: any) {
    super(props)
  }

  componentWillUnmount() {
    this.props.resetErrorsDispatch()
  }

  handleLoginSubmit = (values: any) => {
    this.props.onLogin(values)
  }

  renderLoginForm = () => {
    const { loading, error } = this.props

    return (
      <FormConsumer>
        {({ form, onFormChange }: any) => {
          const values = form.login || {}

          return (
            <>
              {
                error ? (
                  <>
                    <Alert type="error" message={getErrorCode(error)} />
                    <Divider />
                  </>
                ) : null
              }

              <Form
                {...formLayout}
                name="login"
                hideRequiredMark={true}
                initialValues={values}
                onFinish={this.handleLoginSubmit}
                onChange={onFormChange}
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{
                    required: true,
                    message: 'Please input your password'
                  }]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </>
          )
        }}
      </FormConsumer>
    )
  }

  render() {
    return (
      <div className="auth-container">
        <div className="side-landing">
          <Typography.Title type="secondary">Authentication</Typography.Title>
          <Typography.Text type="secondary">Before you proceed, you must authenticate first.</Typography.Text>
        </div>

        <div className="content">
          {
            this.renderLoginForm()
          }
        </div>
      </div>
    )
  }
}
