import React, { createContext } from 'react'
import { BaseContextState, BaseContextProvider } from '../BaseContext'
import { domainListRequest, domainAddRequest, domainRemoveRequest } from '../../services/api'
import { RequestAddDomain, RequestRemoveDomain } from '../../services/api.typings'

// Distribution interface
export interface FoundDomain {
  domain: string,
  distribution: string
}

// Provider states
interface DomainContextState extends BaseContextState {
  domains: Array<string> | Array<FoundDomain> | null
}

// Context data
interface DomainContextData extends DomainContextState {
  resetErrors: Function,
  requestDomainList: Function,
  requestDomainAdd: Function,
  requestDomainRemove: Function,
}

// Create context
const { Provider, Consumer } = createContext({} as DomainContextData)

// Export consumer
export const DomainConsumer = Consumer

export class DomainProvider extends BaseContextProvider {
  state: DomainContextState = {
    domains: null
  }

  /**
   * Make api request for domain listing
   */
  requestDomainList = (distributionId = '', searchTerm = '') => {
    this.setState({
      fetching: true,
      domains: null,
      error: null,
      errorObjects: null
    }, async () => {
      const response = await domainListRequest(distributionId, searchTerm)

      // Process our response
      this.processResponse(response, ['domains'])
    })
  }

  /**
   * Make api request for domain add
   * 
   * @param payload 
   */
  requestDomainAdd = (payload: RequestAddDomain) => {
    this.setState({
      fetching: true,
      success: false,
      error: null,
      errorObjects: null
    }, async () => {
      const response = await domainAddRequest(payload)

      // Process our response
      this.processResponse(response)
    })
  }

  /**
   * Make api request for domain remove
   * 
   * @param payload 
   */
  requestDomainRemove = (payload: RequestRemoveDomain) => {
    this.setState({
      fetching: true,
      success: false,
      error: null,
      errorObjects: null
    }, async () => {
      const response = await domainRemoveRequest(payload)

      // Process our response
      this.processResponse(response)
    })
  }

  render() {
    return (
      <Provider
        value={{
          // States
          ...this.state,

          // Dispatches
          resetErrors: this.resetErrors,
          requestDomainList: this.requestDomainList,
          requestDomainAdd: this.requestDomainAdd,
          requestDomainRemove: this.requestDomainRemove
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}
