export const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
}
export const tailLayout = {
  wrapperCol: { offset: 4, span: 18 },
}

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
}

export const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
}

export { default as Auth } from './Auth'
export { default as Domain } from './Domain'
