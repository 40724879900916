import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { Distribution } from '../../context/DistributionContext'

type DistributionListProps = {
  loading?: boolean
  distributions: Array<Distribution>
  
  loadDistributions: Function
}

export default class DistributionList extends Component<DistributionListProps> {
  static defaultProps = {
    loading: false
  }

  state = {
    loading: false
  }

  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: string, record: any) => <Link to={`/${record.id}`}>{text}</Link>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      responsive: ['md'] as any,
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    }
  ]

  /**
   * Get component state updated from props
   * 
   * @param newProps 
   * @param newState 
   */
  static getDerivedStateFromProps(newProps: any, newState: any) {
    // Making request
    if (newProps.loading && !newState.loading) {
      newState.loading = true
    }

    // Request was made
    if (!newProps.loading && newState.loading) {
      newState.loading = false
    }

    return newState
  }

  componentDidMount() {
    this.props.loadDistributions()
  }

  render() {
    const { loading, distributions } = this.props
    
    return (
      <Table loading={loading} rowKey='id' columns={this.tableColumns} dataSource={distributions} />
    )
  }
}