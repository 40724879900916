import React from 'react'

// Available views
import Auth from '../views/Auth'
import Home from '../views/Home'

// Menu items icons
import { HomeOutlined } from '@ant-design/icons'

// Routes
const routes = [
  {
    path: ['/login', '/register'],
    exact: false,
    isPrivate: false,
    name: 'Autenticação',
    component: Auth
  },
  {
    path: '/:distributionId?',
    exact: true,
    isPrivate: true,
    name: 'Home',
    component: Home
  },
]

// Menu items
export const MenuItems = [
  {
    path: '/',
    name: 'Home',
    icon: <HomeOutlined />
  },
]

export default routes