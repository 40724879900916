import React, { createContext } from 'react'
import { BaseContextState, BaseContextProvider } from '../BaseContext'
import { distributionListRequest, distributionDetailRequest } from '../../services/api'

// Distribution interface
export interface Distribution {
  id: string,
  name: string,
  domains?: Array<string>,
  certificate: string,
  createdAt: string,
  updatedAt: string
}

// Provider states
interface DistributionContextState extends BaseContextState {
  distributions: Array<Distribution> | null,
  distribution: Distribution | null
}

// Context data
interface DistributionContextData extends DistributionContextState {
  resetErrors: Function,
  requestDistributionList: Function,
  requestDistributionDetail: Function
}

// Create context
const { Provider, Consumer } = createContext({} as DistributionContextData)

// Export consumer
export const DistributionConsumer = Consumer

export class DistributionProvider extends BaseContextProvider {
  state: DistributionContextState = {
    distributions: [],
    distribution: null
  }

  /**
   * Make api request for login
   * 
   * @param payload 
   */
  requestDistributionList = () => {
    this.setState({
      fetching: true,
      error: null,
      errorObjects: null
    }, async () => {
      const response = await distributionListRequest()

      // Process our response
      this.processResponse(response, ['distributions'])
    })
  }

  /**
   * Make api request for login
   * 
   * @param payload 
   */
  requestDistributionDetail = (payload: any) => {
    this.setState({
      fetching: true,
      error: null,
      errorObjects: null
    }, async () => {
      const response = await distributionDetailRequest(payload)

      // Process our response
      this.processResponse(response, ['distribution'])
    })
  }

  render() {
    return (
      <Provider
        value={{
          // States
          ...this.state,

          // Dispatches
          resetErrors: this.resetErrors,
          requestDistributionList: this.requestDistributionList,
          requestDistributionDetail: this.requestDistributionDetail
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}


