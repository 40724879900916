import React, { Component } from 'react'
import { PageHeader, Input, Space, Button, Table, Skeleton, Result, Modal, Popconfirm } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Domain } from '../Forms'
import { DistributionConsumer } from '../../context/DistributionContext'
import { TaskConsumer } from '../../context/TaskContext'
import { DomainProvider, DomainConsumer } from '../../context/DomainContext'

type DistributionDetailProps = {
  distributionId: string
  loadDistribution: Function
  history: any
}

export default class Distribution extends Component<DistributionDetailProps> {

  state = {
    selectedRowKeys: [],
    modalVisible: false
  }

  // Search input reference
  searchInput: any = null

  tableColumns = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '@primary-color' : undefined }} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder='Search for product'
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      )
    }
  ]

  componentDidMount() {
    const { distributionId, loadDistribution } = this.props

    // Load category detail
    if (distributionId) {
      loadDistribution(distributionId)
    }
  }

  onSelectChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys })
  }

  handleBackButton = () => {
    this.props.history.goBack()
  }

  handleOpenModal = () => {
    this.setState({
      modalVisible: true
    })
  }

  handleCloseModal = (dispatch?: Function) => {
    this.setState({
      modalVisible: false
    }, () => {
      // Reload tasks
      if (dispatch) {
        dispatch()
      }
    })
  }

  handleTableChange = (filters: any, dispatch: any) => {
    const { distributionId } = this.props
    let payload: any = {}

    // Parse filters to payload params
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        payload[key] = filters[key].toString()
      }
    })

    dispatch(distributionId, payload['domain'] || '')
  }

  renderActions = (removeDispatch: any) => {
    const { selectedRowKeys } = this.state

    return (
      <>
        <Button type="primary" shape="round" onClick={this.handleOpenModal}>
          Add
        </Button>

        {
          selectedRowKeys.length > 0 ? (
            <Popconfirm
              placement="leftBottom"
              title="You really want to remove the selected domains?"
              onConfirm={() => removeDispatch({ domains: this.state.selectedRowKeys, distribution: this.props.distributionId })}
              okText="Yes"
              cancelText="No"
            >
              <Button danger type="dashed" shape="round">
                Remove
              </Button>
            </Popconfirm>
          ) : null
        }
      </>
    )
  }

  render() {
    const { selectedRowKeys, modalVisible } = this.state

    return (
      <DistributionConsumer>
        {({ fetching, distribution }) => {
          if (fetching) {
            return <Skeleton />
          }

          if (!distribution) {
            return <Result
              status="404"
              title="404"
              subTitle="Sorry, the distribution you requested, does not exist."
            />
          }

          const domainsParsed = distribution.domains?.map(str => {
            return {
              domain: str
            }
          }) || []
          return (
            <TaskConsumer>
              {({ requestTaskList }) => (
                <DomainProvider>
                  <DomainConsumer>
                    {({ domains, requestDomainRemove, requestDomainList }) => {
                      return (
                        <>
                          <PageHeader title={distribution.name} onBack={this.handleBackButton} extra={this.renderActions(requestDomainRemove)}>
                            <Table
                              rowKey="domain"
                              columns={this.tableColumns}
                              dataSource={domains as any || domainsParsed}
                              onChange={( pagination, filters, sorter ) => this.handleTableChange(filters, requestDomainList)}
                              rowSelection={{
                                selectedRowKeys: selectedRowKeys,
                                onChange: this.onSelectChange
                              }}
                            />
                          </PageHeader>

                          <Modal
                            title='Add Domain'
                            visible={modalVisible}
                            footer={null}
                            onCancel={() => this.handleCloseModal(requestTaskList)}
                          >
                            { 
                              modalVisible ? (
                                <Domain distributionId={distribution.id} onSuccess={() => this.handleCloseModal(requestTaskList)} />
                              ) : null 
                            }
                          </Modal>
                        </>
                      )
                    }}
                  </DomainConsumer>
                </DomainProvider>
              )}
            </TaskConsumer>
          )
        }}
      </DistributionConsumer>
    )
  }
} 
