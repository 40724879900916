import React, { Component } from 'react'
import { Card } from 'antd'
import DistributionList from '../../components/DistributionList'
import DistributionDetail from '../../components/DistributionDetail'
import { DistributionProvider, DistributionConsumer } from '../../context/DistributionContext'

export default class Home extends Component {
  render() {
    const { history, match: { params: { distributionId } } }: any = this.props

    return (
      <DistributionProvider>
        <DistributionConsumer>
          {({ fetching, distributions, distribution, requestDistributionList, requestDistributionDetail }) => {
            // Show detail of a category
            if (distributionId && distributionId !== 'add') {
              return <DistributionDetail distributionId={distributionId} history={history} loadDistribution={requestDistributionDetail} />
            }

            return (
              <Card title='Distributions'>
                <DistributionList
                  loading={fetching}
                  distributions={distributions || []}
                  loadDistributions={requestDistributionList}
                />
              </Card>
            )
          }}
        </DistributionConsumer>
      </DistributionProvider>
    )
  }
}
